
import { defineComponent } from "vue";
import Photos from '../components/Photos.vue'

export default defineComponent({
    name: "Home",
    components:{
      Photos
    },
    data: function(){
      return{
        pass: "" as string,
        error: "" as string,
        Auth: false as boolean
      }
    },
    methods:{
      async Login(){
        this.error = "";

        if(!this.pass)
        {
          this.error = "Gelieve een wachtwoord in te vullen";
          return;
        }  

        if(this.pass == "hierisdatfeestje")
          this.Auth = true;
        else
          this.error = "Wachtwoord is niet juist."
      }
    }
});
