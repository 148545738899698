
  import {defineComponent} from "vue";
  import store from "../../BLL/store";
  
  export default defineComponent({
      name: "Home",
      methods:{
        async Logout(){
          await store.dispatch('userStore/Logout');
          this.$router.push('/');
        }
      }
  });
  