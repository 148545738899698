import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 mt-3"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-primary mt-3",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.Download && _ctx.Download(...args)))
    }, "Download foto's"),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(138, (index) => {
      return _createElementVNode("div", {
        key: index,
        class: "row"
      }, [
        (index != 111)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                src: require('../assets/' + index + '.jpg'),
                alt: "ph",
                class: "img-fluid"
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true)
      ])
    }), 64))
  ]))
}