
  import { defineComponent } from "vue";
  import {Config} from '../../DAL/Config'

  export default defineComponent({
      name: "Photos",
      methods:{
        Download(){
            window.open(Config.Url() + '/Common/getfile?filename=straatfeest/straatfeest.zip');
        }
      }
  });
  